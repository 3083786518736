import { setModal } from "@redux/shared/slices/modal";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import styles from "./footer.module.scss";

const yearNow = new Date();

export default function Footer(): ReactElement {
    const dispatch = useDispatch();

    const footerMenu = [
        {
            title: "Terms & Conditions",
            onClick: () =>
                dispatch(setModal({ active: true, content: "terms" })),
        },
        {
            title: "Privacy Policy",
            onClick: () =>
                dispatch(setModal({ active: true, content: "privacy" })),
        },
    ];

    return (
        <footer
            className={`${styles["footer"]}  text-center lg:px-6 lg:py-0  max-w-7xl mx-auto`}
        >
            <div
                className={`${styles["inner"]} lg:flex lg:justify-between lg:items-center flex-row-reverse lg:mx-auto px-4 lg:px-0 py-6 pb-9 lg:py-4 `}
            >
                <div className="footer-menu">
                    <ul className="flex justify-center mb-3 lg:mb-0 lg:children:last:pr-0 children:last:border-r-0">
                        {footerMenu.map((item, index) => (
                            <li
                                key={index}
                                className={`px-2 font-medium lg:font-light text-sm lg:leading-4 ${styles["menu-item"]}`}
                            >
                                <a
                                    className=" cursor-pointer"
                                    onClick={item.onClick}
                                >
                                    <span>{item.title}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="font-light text-sm">
                    <span>
                        <span>©</span> <span>{yearNow.getFullYear()}</span>{" "}
                        <a href="https://fleettrackingcosts.com">
                            FleetTrackingCosts
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
}
